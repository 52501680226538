<template>
    <div style="background-color: #f2f2f2; margin-top: 8px">
        <el-card style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" size="small" label-width="85px">
                <el-row style="margin-top: 16px">
                    <!--机构类型和机构列表组件-->
                    <ef-dept-type-and-dept-select
                        @selectDept="setDept"
                        @selectDeptType="setDeptType"
                        :need-default-dept-type="true"
                    />
                    <el-col :span="8">
                        <el-form-item label="查询条件">
                            <el-input placeholder="商品名称/条码" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleManualQuery"
                size="small"
                v-if="hasPrivilege('menu.report.wma.cost.change.detail.history.open')"
            >
                查询
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.report.wma.cost.change.detail.history.export')"
            >
                导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <el-card style="margin-top: 8px">
            <el-table
                id="table"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="460"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column
                    prop="goodsName"
                    label="商品名称"
                    width="180"
                    fixed="left"
                    v-if="showColumn('goodsName')"
                />
                <!--商品条码-->
                <el-table-column
                    prop="bars"
                    label="商品条码"
                    width="180"
                    fixed="left"
                    v-if="showColumn('bars')"
                    :key="Math.random()"
                >
                    <template slot-scope="scope">
                        <div v-for="b in scope.row.bars" :key="b">{{ b }}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="deptName"
                    label="机构名称"
                    width="180"
                    fixed="left"
                    v-if="showColumn('deptName')"
                />
                <el-table-column prop="bizCode" label="单据号" width="170" v-if="showColumn('bizCode')" />
                <el-table-column prop="bizType" key="bizType" label="单据类型" width="110" v-if="showColumn('bizType')">
                    <template slot-scope="scope">
                        <span>{{ scope.row.bizType | bizTypeDesc }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="operateTime" label="操作时间" width="150" v-if="showColumn('operateTime')" />
                <el-table-column prop="operator" label="操作人" width="110" v-if="showColumn('operator')" />
                <el-table-column prop="count" label="出入库数量" width="120" v-if="showColumn('count')" />
                <el-table-column prop="cost" label="出入库成本" width="120" v-if="showColumn('cost')" />
                <el-table-column prop="resultCount" label="剩余数量" width="150" v-if="showColumn('resultCount')" />
                <el-table-column prop="resultCost" label="剩余库存成本" width="150" v-if="showColumn('resultCost')" />
                <el-table-column
                    prop="resultCostPrice"
                    label="剩余库存成本单价"
                    width="150"
                    v-if="showColumn('resultCostPrice')"
                />
            </el-table>
            <ef-pagination
                :total="total"
                :default-limit="form.limit"
                @pageChange="pageChange"
                :current-page="form.page"
            />
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
    </div>
</template>

<script>
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import EfPagination from 'components/EfPagination';
import Util from '@/js/Util';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import UrlUtils from 'js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'WmaStockCostChangeDetailHistory',
    components: { EfPagination, EfDeptTypeAndDeptSelect, EfStartDate, EfEndDate, CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            form: {
                deptType: null,
                deptCode: null,
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                search: '',
                page: 1,
                limit: 100,
            },
            total: 0,
            tableData: [],
            wmaBizType2Desc: Util.allWmaBizType2Desc(),
        };
    },
    filters: {
        bizTypeDesc(bizType) {
            return Util.bizTypeDesc(bizType);
        },
    },
    mounted() {
        Util.setIntervalAndTimeout(
            () => {
                return this.form.deptCode !== null;
            },
            () => {
                return true;
            },
            () => {
                this.handleQuery();
            },
            3000
        );
    },
    methods: {
        async handleQuery() {
            const rst = await this.$efApi.wmaStockCostChangeReportApi.list(this.form);
            this.tableData = rst.data;
            this.total = rst.count;
        },
        handleManualQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        filterCostSum(bizTypeCostSums, bizType) {
            return bizTypeCostSums.find(function (e) {
                return bizType === e.bizType;
            });
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
        },
        setDeptType(deptType) {
            this.form.deptType = deptType;
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.handleQuery();
        },
        handleExport() {
            UrlUtils.Export(
                this,
                '移动加权库存成本流水',
                `${this.$efApi.wmaStockCostChangeReportApi.baseUrl}/exportList`,
                this.form
            );
        },
        handlePrint() {
            this.printHTML('table', this.$route.meta.title);
        },
    },
};
</script>
